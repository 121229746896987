import React, { useState } from 'react';
import { useIntl } from '@utils/localize';

import { Close, Info } from '@components/Icon';
import NavButton from '@components/NavButton';
import ImagePreview from '@components/storyPreview/Image';
import * as styled from './styles';

function Lightbox({ caption, credit, image, onClose, onNext, onPrev, parent, title }) {
  const intl = useIntl();

  const [infoOpen, setInfoOpen] = useState(false);

  const handleCloseInfo = () => setInfoOpen(false);
  const handleOpenInfo = () => setInfoOpen(true);

  return (
    <styled.Wrapper>
      <styled.Inner>
        <styled.Header>
          <styled.Heading level={400}>{title}</styled.Heading>
          <styled.IconButton onClick={onClose}>
            <Close size={18} />
          </styled.IconButton>
        </styled.Header>
        <styled.ImageWrapper>
          <styled.Image
            fluid={image.asset.fluid}
            imgStyle={{ objectFit: 'contain' }}
          />
        </styled.ImageWrapper>
        <styled.Info open={infoOpen}>
          <styled.Content>
            <styled.Header>
              <styled.Heading level={400}>{title}</styled.Heading>
            </styled.Header>
            <styled.Body size="small">{caption}</styled.Body>
            {credit && <styled.Credit>({credit})</styled.Credit>}
          </styled.Content>
          {parent && (
            <styled.ParentWrapper>
              <styled.Parent level={400}>{intl.formatMessage({ id: 'appearsIn' })}</styled.Parent>
              <ImagePreview {...parent} type="sidebar" />
            </styled.ParentWrapper>
          )}
          <styled.Footer>
            <styled.IconButton onClick={handleCloseInfo}>
              <Close size={20} />
            </styled.IconButton>
          </styled.Footer>
        </styled.Info>
        <styled.Actions>
          <NavButton
            direction="prev"
            onClick={onPrev}
            size="small"
            colorTheme="yellow"
          />
          {(caption || title || credit) && (
            <styled.IconButton onClick={handleOpenInfo}>
              <Info size={28} />
            </styled.IconButton>
          )}
          <NavButton
            direction="next"
            onClick={onNext}
            size="small"
            colorTheme="yellow"
          />
        </styled.Actions>
      </styled.Inner>
    </styled.Wrapper>
  );
}

export default Lightbox;
