import React from 'react';
import { AnimatePresence } from 'framer-motion';
import { useIntl } from '@utils/localize';

import { Close } from '@components/Icon';
import NavButton from '@components/NavButton';
import ImagePreview from '@components/storyPreview/Image';
import * as styled from './styles';

const variants = {
  enter: (direction) => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
};

function Lightbox({
  caption,
  credit,
  direction,
  image,
  onClose,
  onNext,
  onPrev,
  page,
  parent,
  title,
}) {
  const intl = useIntl();

  return (
    <styled.Wrapper>
      <styled.Inner>
        <styled.ImageWrapper>
          <styled.Prev>
            <NavButton
              direction="prev"
              onClick={onPrev}
              size="large"
              colorTheme="yellow"
            />
          </styled.Prev>
          <AnimatePresence initial={false} custom={direction}>
            <styled.AnimateWrapper
              key={page}
              custom={direction}
              variants={variants}
              initial="enter"
              animate="center"
              exit="exit"
              transition={{
                x: { type: 'spring', stiffness: 300, damping: 200 },
                opacity: { duration: 0.2 },
              }}
            >
              <styled.Image
                fluid={image.asset.fluid}
                imgStyle={{ objectFit: 'contain' }}
              />
            </styled.AnimateWrapper>
          </AnimatePresence>
          <styled.Next>
            <NavButton
              direction="next"
              onClick={onNext}
              size="large"
              colorTheme="yellow"
            />
          </styled.Next>
        </styled.ImageWrapper>
        <styled.InfoWrapper>
          <styled.Info hasTitle={title}>
            <styled.Content>
              <styled.Header>
                <styled.Heading level={400}>{title}</styled.Heading>
                <styled.IconButton onClick={onClose}>
                  <Close />
                </styled.IconButton>
              </styled.Header>
              <styled.Body size="small">{caption}</styled.Body>
              {credit && <styled.Credit>({credit})</styled.Credit>}
            </styled.Content>
            {parent && (
              <styled.ParentWrapper>
                <styled.Parent level={400}>{intl.formatMessage({ id: 'appearsIn' })}</styled.Parent>
                <ImagePreview {...parent} type="sidebar" />
              </styled.ParentWrapper>
            )}
          </styled.Info>
        </styled.InfoWrapper>
      </styled.Inner>
    </styled.Wrapper>
  );
}

export default Lightbox;
